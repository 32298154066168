.nb-text {
    &--bold {
        font-weight: 700;
    }

    &--italic {
        font-style: italic;
    }

    &--underline {
        text-decoration: underline;
    }

    &--center {
        text-align: center;
    }

    &--pre-wrap {
        white-space: pre-wrap;
    }
}