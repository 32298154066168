@import 'mixins/media';

#Page {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .ng-page {
        flex-grow: 1;
    }

    header, footer, .ng-page {
        flex-shrink: 0;
    }
}

.nb-container {
    min-height: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 50px;
    width: 100%;
    
    @include medium-device {
        margin-left: auto;
        margin-right: auto;
        width: $breakpoint-medium-device-width;
    }

    @include large-device {
        margin-left: auto;
        margin-right: auto;
        width: $breakpoint-large-device-width;
    }

    @include huge-device {
        margin-left: auto;
        margin-right: auto;
        width: $breakpoint-huge-device-width;
    }
}

.nb {
    &-layout {

        text-align: center;

        @include medium-device {
            &--horizontal {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            &__item {
                flex: 1 1 0;
            }
        }
    }
}