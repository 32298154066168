.nb-section {
    padding: 60px 20px;
    margin-bottom: 20px;
    
    &--highlight-light {
        background-color: #646de2;
        border-radius: 5px;
        box-shadow: 0 2px 5px 0px black;
    }

    &--highlight-dark {
        box-shadow: inset 0 2px 5px 0px black;
        border-radius: 5px;
        background-color: #20294a;
    }
}

.nb-container {
    .nb-section:first-child:not(.nb-section--highlight-light):not(.nb-section--highlight-dark) {
        padding-top: 30px;
    } 
}