@import 'mixins/normalize';
@import 'mixins/typography';
@import 'mixins/colors';
@import 'mixins/space';
@import 'mixins/media';

html, body {
    height: 100%;
    padding: 0;
    margin: 0;
}

body {
    font-size: 16px;
    font-family: 'Fira Code';
    font-style: normal;

    background-color: $color-background-primary;
    color: $color-font-primary;
}

h1, h2, h3, h4, h5, h6 {
    text-align: center;

    @include medium-device {
        text-align: left;
    }
}

h1, h2, h3, h4, h5, h6, p, a, a:visited {
    color: $color-font-primary;
    margin-bottom: $default-space;
}

h1 {
    font-size: $font-header-1-size;
}
h2 {
    font-size: $font-header-2-size;
}
h3 {
    font-size: $font-header-3-size;
} 
h4 {
    font-size: $font-header-4-size;
} 
h5 {
    font-size: $font-header-5-size;
}
p {
    font-size: $font-size-default;
}

ul,ol {
    list-style-type: none;
    padding-left: 0;
}

@import 'layout';
@import 'text';
@import './atoms/nb-card';
@import './atoms/nb-section';