$breakpoint-small-device-width: 320px;
$breakpoint-medium-device-width: 768px;
$breakpoint-large-device-width: 1024px;
$breakpoint-huge-device-width: 1440px;

@mixin medium-device {
    @media(min-width: $breakpoint-medium-device-width) {
        @content;
    }
}

@mixin large-device {
    @media(min-width: $breakpoint-large-device-width) {
        @content;
    }
}

@mixin huge-device {
    @media(min-width: $breakpoint-huge-device-width) {
        @content;
    }
}