* {
    box-sizing: border-box;
    font-family: inherit;
    font-size: inherit;
}

h1, h2, h3, h4, h5, h6, p, span {
    margin: 0;
    padding: 0;
    line-height: auto;
}

nav {
    margin: 0;
    padding: 0;
}

span {
    font-size: inherit;
    color: inherit;
}

img {
    max-width: 100%;
}