.nb-card {
    margin: 10px;

    &__title {
        font-weight: bolder;
        margin: 1rem;
        font-size: 1.2;
        font-style: italic;
        display: block;
    }

    &__image {
        border-radius: 5px;
    }
}